import { Link } from "@StarberryUtils"
import React, { useEffect, useState } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import {
  Button,
  Navbar,
  Nav,
  Form,
  Container,
  FormControl,
  Row,
  Col,
  dark,
} from "react-bootstrap"
import "./ContactTeam.scss"

//search-bottom-image
import SearchBottomImgDesktop from "../../../images/Static/contact-team-bottom-d.jpg"
import SearchBottomImgTablet from "../../../images/Static/contact-team-bottom-t.jpg"
import SearchBottomImgMobile from "../../../images/Static/contact-team-bottom-m.jpg"
import LandAndNewHomes from "../../../images/Search/land-new-homes.jpg"
import CustomerReviews from "../../../images/Search/customer-reviews.jpeg"
import Community from "../../../images/Search/community.png"
import {
  inViewOptions,
  easeFunc,
  containerVariants,
  delayItemsFromBottom,
} from "../../utils/animations"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
import ContactForm from "../../forms/contact"
import Modal from "react-bootstrap/Modal"
import MemberContact from "../../forms/member-contact"
import RegisterFormNewHome from "../../forms/register-with-new-home"

const contentItemText = {
  hidden: {
    opacity: 0,
    x: -50,
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      ...easeFunc,
      duration: 0.6,
    },
  },
}
const ContactTeam = props => {
  const [showModal, setShowModal] = useState(false)

  const handleModal = () => {
    setShowModal(true)
  }

  const handleclosing = () => {
    setShowModal(false)
  }

  return (
    <React.Fragment>
      <InView {...inViewOptions}>
        {({ ref, inView }) => (
          <motion.section
            className="contact-team bottom-team-module"
            ref={ref}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            variants={containerVariants}
          >
            <div className="container-custom">
              <div className="search-bottom-block">
                <motion.div
                  className="search-bottom-image"
                  variants={delayItemsFromBottom}
                >
                  {props.pageClass === "newhomespage" ? (
                    <div>
                      <picture>
                        <source
                          media="(min-width:992px)"
                          srcSet={LandAndNewHomes}
                        />
                        <source
                          media="(min-width:768px)"
                          srcSet={LandAndNewHomes}
                        />
                        <img
                          src={LandAndNewHomes}
                          alt="Land and New Homes -  Location Location"
                        />
                      </picture>
                    </div>
                  ) : props.pageClass === "reviews-landing"  || props.pageClass === "terms-condition" || props.pageClass === "privacy-policy" || props.pageClass === "regulationfeespage" ? (
                    <div>
                      <picture>
                        <source
                          media="(min-width:992px)"
                          srcSet={CustomerReviews}
                        />
                        <source
                          media="(min-width:768px)"
                          srcSet={CustomerReviews}
                        />
                        <img
                          src={CustomerReviews}
                          alt="Customer Reviews -  Location Location"
                        />
                      </picture>
                    </div>
                  ) : props.pageClass === "communitypage" ? (
                    <div>
                      <picture>
                        <source media="(min-width:992px)" srcSet={Community} />
                        <source media="(min-width:768px)" srcSet={Community} />
                        <img
                          src={Community}
                          alt="Community -  Location Location"
                        />
                      </picture>
                    </div>
                  ) : props.communityDetails ? (
                    <div>
                      <picture>
                        <source media="(min-width:992px)" srcSet={Community} />
                        <source media="(min-width:768px)" srcSet={Community} />
                        <img
                          src={Community}
                          alt="Community -  Location Location"
                        />
                      </picture>
                    </div>
                  ) : (
                    <div>
                      <picture>
                        <source
                          media="(min-width:992px)"
                          srcSet={SearchBottomImgDesktop}
                        />
                        <source
                          media="(min-width:768px)"
                          srcSet={SearchBottomImgTablet}
                        />
                        <img
                          src={SearchBottomImgMobile}
                          alt="Take the stress out of moving home -  Location Location"
                        />
                      </picture>
                    </div>
                  )}
                </motion.div>

                {props.pageClass === "communitypage" ? (
                  <div
                    // animateIn="fadeInUp"
                    className="search-bottom-content"
                  >
                    <motion.h3 variants={contentItemText}>
                      The power of community.
                    </motion.h3>
                    <motion.p variants={contentItemText}>
                      We love to support local businesses, schools, charities,
                      and people. Know someone who’d benefit from our support?
                    </motion.p>
                    <motion.div variants={contentItemText}>
                      <a onClick={handleModal} className="btn btn-yellow">
                        Contact Us
                      </a>
                    </motion.div>
                  </div>
                ) : props.pageClass === "reviews-landing" || props.pageClass === "terms-condition" || props.pageClass === "privacy-policy" || props.pageClass === "regulationfeespage" ? (
                  <div
                    // animateIn="fadeInUp"
                    className="search-bottom-content"
                  >
                    <motion.h3 variants={contentItemText}>
                      Because you deserve better.
                    </motion.h3>
                    <motion.p variants={contentItemText}>
                      We go above and beyond to deliver a world-class service
                      for our clients because we feel everybody; whether you're
                      buying, selling, letting or renting, deserves a memorable
                      experience.
                    </motion.p>
                    <motion.div variants={contentItemText}>
                      <div className="reviewpage-buttons">
                        <Link
                          to="/contact"
                          className="btn btn-yellow"
                          style={{ marginRight: "20px" }}
                        >
                          Contact Us
                        </Link>
                        <Link
                          to="/property-services/sell/property-valuation"
                          className="btn btn-yellow last-button"
                        >
                          Book a Valuation
                        </Link>
                      </div>
                    </motion.div>
                  </div>
                ) : props.pageClass === "newhomespage" ? (
                  <div
                    // animateIn="fadeInUp"
                    className="search-bottom-content"
                  >
                    <motion.h3 variants={contentItemText}>
                      Maximise the success of your development.
                    </motion.h3>
                    <motion.p variants={contentItemText}>
                      From architects to interior designers, we have everything
                      you need to bring your development to life. We'll then put
                      our teams to work selling or letting all the units
                      promptly. So, whether you've just bought a plot of land or
                      you've almost finished construction, give us a call today
                      to discuss how we can help.
                    </motion.p>
                    <motion.div variants={contentItemText}>
                      {/* <Link to="/contact" className="btn btn-yellow">
                        Contact Us
                      </Link> */}
                      <a onClick={handleModal} className="btn btn-yellow">
                        Contact Us
                      </a>
                    </motion.div>
                  </div>
                ) : props.communityDetails ? (
                  <div
                    // animateIn="fadeInUp"
                    className="search-bottom-content"
                  >
                    <motion.h3 variants={contentItemText}>
                      The power of community.
                    </motion.h3>
                    <motion.p variants={contentItemText}>
                      We love to support local businesses, schools, charities,
                      and people. Know someone who’d benefit from our support?
                    </motion.p>
                    <motion.div variants={contentItemText}>
                      <a onClick={handleModal} className="btn btn-yellow">
                        Contact Us
                      </a>
                    </motion.div>
                  </div>
                ) : (
                  <div
                    // animateIn="fadeInUp"
                    className="search-bottom-content"
                  >
                    <motion.h3 variants={contentItemText}>
                      Take the stress out of moving home.
                    </motion.h3>
                    <motion.p variants={contentItemText}>
                      If you’re a hands-off kind of landlord, we have good news.
                      Our Managed service means we’ll do everything for you –
                      from finding the best tenants to keeping you on the right
                      side of all the rental rules and regulations.
                    </motion.p>
                    <motion.div variants={contentItemText}>
                      <Link to="/purpose/our-people" className="btn btn-yellow">
                        Contact our team
                      </Link>
                    </motion.div>
                  </div>
                )}
              </div>
            </div>
          </motion.section>
        )}
      </InView>
      <>
        <Modal
          show={showModal}
          centered={true}
          onHide={handleclosing}
          backdrop="static"
          keyboard={false}
          dialogClassName="modal-90w modal-form-buy-sell-rent modal-form reviewPopup"
        >
          <Modal.Header closeButton className="contact-close-btn">
            <Modal.Title className="w-100">Contact Our Team</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p></p>
            {props.pageClass === "newhomespage" ? (
              <RegisterFormNewHome />
            ) : (
              <MemberContact />
            )}
          </Modal.Body>
        </Modal>
      </>
    </React.Fragment>
  )
}

export default ContactTeam
